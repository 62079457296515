<template>
  <div class="o-flex-region">
    <div class="scenario-label">
      Featured<br>Scenarios
    </div>
    <ul class="tabs o-flex-region scenario-buttons">
      <li class="btn-flex"><a class="btn-link" @click="setScenario(1)"
        :class="{ active: selected===1 && clean }">1
      </a></li>
      <li class="btn-flex"><a class="btn-link" @click="setScenario(2)"
        :class="{ active: selected===2 && clean }">2
      </a></li>
      <li class="btn-flex"><a class="btn-link" @click="setScenario(3)"
        :class="{ active: selected===3 && clean }">3
      </a></li>
      <li class="btn-flex"><a class="btn-link" @click="setScenario(4)"
        :class="{ active: selected===4 && clean }">4
      </a></li>
    </ul>
  </div>
</template>

<script>

const scenarios = [
  // scenario 1
  {
    name: 1,

    acc: 10,
    brv: 20000,
    fgf2_conc: 1e-4,
    fgf2_cost: 2005000,
    gcbm: 1.78e-2,
    gcrc: 4.13e-7,
    hpd: 24,
    mt: 240,

    basemedia: 3.12,
    tgfb: 80900000,
    transferrin: 400,
    aa2p: 7.84,
    nahco3: 0.01,
    nase: 0.1,
    insulin: 340

  },
  // scenario 2
  {
    name: 2,

    acc: 95,
    brv: 20000,
    fgf2_conc: 5e-5,
    fgf2_cost: 1002500,
    gcbm: 2.67e-2,
    gcrc: 2.07e-7,
    hpd: 16,
    mt: 156,

    basemedia: 3.12,
    tgfb: 80900000,
    transferrin: 400,
    aa2p: 7.84,
    nahco3: 0.01,
    nase: 0.1,
    insulin: 340
  },
  // scenario 3
  {
    name: 3,

    acc: 95,
    brv: 20000,
    fgf2_conc: 5e-5,
    fgf2_cost: 0,
    gcbm: 2.67e-2,
    gcrc: 2.07e-7,
    hpd: 16,
    mt: 156,

    basemedia: 3.12,
    tgfb: 80900000,
    transferrin: 400,
    aa2p: 7.84,
    nahco3: 0.01,
    nase: 0.1,
    insulin: 340
  },
  // scenario 4
  {
    name: 4,

    acc: 200,
    brv: 20000,
    fgf2_conc: 0,
    fgf2_cost: 0,
    gcbm: 3.56e-2,
    gcrc: 4.13e-8,
    hpd: 8,
    mt: 24,

    basemedia: 0.24,
    tgfb: 0,
    transferrin: 0,
    aa2p: 0,
    nahco3: 0,
    nase: 0,
    insulin: 0
  }
]

export default {
  name: 'ScenarioButtons',
  props: {
    // clean scenario settings? (not user modified)
    clean: {
      type: Boolean,
      required: true
    }
  },
  data: function() {
    return {
      selected: 1
    }
  },
  methods: {
    setScenario: function(s) {
      this.selected=s;
      this.$emit("scenarioChanged", scenarios[s-1]);
    }
  }
}
</script>

<style scoped>
.scenario-label {
  display: flex;
  line-height: 1.1em;
  flex: 0 0 9ch;
  justify-content: center;
  align-items: center;
}
.scenario-buttons {
  flex: 1;
  margin: 0;
}
.btn-flex {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
}
.btn-link {
  flex: 0 1 100%;
  padding: 0px;
}
</style>
