<template>
  <div>
    <span class="scenarioName">{{ scenarioName }}</span>{{ scenarioSummary }}
  </div>
</template>

<script>
const names = [
  "Scenario 1",
  "Scenario 2",
  "Scenario 3",
  "Scenario 4",
  "Custom Scenario"
]
const summaryText = [
  " represents a baseline scenario without any technical innovation using existing scaled technology.",
  " acts as a mid-point between each technical challenge highlighted in the model being solved/minimized and the baseline scenario.",
  " is the same as Scenario 2 except it accounts for an innovation which reduces the cost of the growth factor FGF-2 to zero.",
  " represents a scenario at which almost every technical challenge detailed in our paper/model has been minimized or solved. This scenario does not represent the current state of technology.",
  ""
]
export default {
  props: {
    selectedScenario: {
      required: true
    }
  },
  computed: {
    index: function() {
      return this.selectedScenario ? this.selectedScenario.name - 1 : 4
    },
    scenarioName: function() {
      return names[this.index]
    },
    scenarioSummary: function() {
      return summaryText[this.index]
    }
  }
}
</script>

<style scoped>
.scenarioName {
  font-weight: bold;
}
</style>
