<template>
  <div class="page o-box">
    <main class="l-main">
      <div class="l-quad--half custom-layout">

        <!-- Controls -->
        <div class="l-quad__region">
          <div class="panel o-box model-contols">
            <cost-model @modelUpdate="(scen, out) => {this.scenario=scen; model_outputs.splice(4, 1, out)}"/>
          </div>
        </div>

        <!-- Outputs -->
        <div class="l-quad__region">
          <div class="panel o-box--small model-summary">
            <scenario-summary :selectedScenario="scenario"/>
          </div>
          <div class="l-quad--half plot-layout">
            <div class="l-quad__region">
              <div class="panel o-box--small">
                <barplot1 :plotdata="model_outputs"/>
              </div>
            </div>
            <div class="l-quad__region">
              <div class="panel o-box--small">
                <barplot2 :plotdata="model_outputs"/>
              </div>
            </div>
          </div>
          <div class="l-quad--full">
            <div class="panel o-box--small tablewrapper">
              <output-table :model_outputs="model_outputs"/>
            </div>
          </div>
          <download-data :custom_scenario="model_outputs[4]" />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import CostModel from "./CostModel.vue"
import ScenarioSummary from "./ScenarioSummary.vue"
import Barplot1 from "./Barplot1.vue"
import Barplot2 from "./Barplot2.vue"
import OutputTable from "./OutputTable.vue"
import DownloadData from "./DownloadData.vue"

// featured scenario data
import ModelOutputs from '../../../data/scenario_data.json'

// match initial custom scenario inputs to "scenario 1"
var custom_scenario = {...ModelOutputs[0]}
custom_scenario['name'] = 'scenario custom'
ModelOutputs.push(custom_scenario)

export default {
  name: 'Model',
  data: function() {
    return {
      scenario: null,
      model_outputs: ModelOutputs
    }
  },
  components: {
    CostModel,
    ScenarioSummary,
    Barplot1,
    Barplot2,
    OutputTable,
    DownloadData
  }
}
</script>

<style scoped>
  .custom-layout {
    margin: 0 3%;
    grid-template-columns: 1fr 3fr;
  }
  .model-contols, .model-summary {
    background-color: #FBF6E5;
    border: 1px solid #B2BDCF;
  }
  .model-summary {
    line-height: 18px;
  }
  .plot-layout {
    grid-template-columns: 2fr 3fr;
  }
  .panel{
    margin-bottom: 1em;
  }
  .tablewrapper {
    overflow-x: scroll;
  }
</style>
