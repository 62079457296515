<template>
  <div class="panel model-inputs">
    <scenario-buttons ref="scBtn" :clean="Boolean(selectedScenario)" @scenarioChanged="selectedScenario = $event"/>
    <hr class="my-hr">
    
    <model-input
      ref="acc_slider"
      label="Achievable cell concentration (million cells/mL)"
      units="million cells/mL"
      definition="Number of cells contained within a milliliter of media"
      :default="model_inputs.ACC"
      :min="5"
      :max="400"
      :interval="1"
      fmtFun="commaFmt"
      @valueUpdate="(v, s)=>this.updateValue('ACC', v, s)"></model-input>
    
    <model-input
      label="Working volume of the bioreactors (L)"
      units="L"
      definition="Volume of the bioreactor that can be utilized for cell growth and maturation"
      :default="model_inputs.BRWV"
      :min="1e3"
      :max="1e6"
      :interval="1e3"
      fmtFun="commaFmt"
      @valueUpdate="(v, s)=>updateValue('BRWV', v, s)"></model-input>
    
    <model-input
      label="Concentration of FGF-2 (g/L)"
      units="g/L"
      definition="Grams of fibroblast growth factor-2 (a growth factor which acts in a variety of developmental processes) contained in a liter of media"
      :default="model_inputs.FGF2Con"
      :min="0"
      :max="1e-3"
      :interval="1e-5"
      :precision="5"
      fmtFun="sciFmt"
      @valueUpdate="(v, s)=>updateValue('FGF2Con', v, s)"></model-input>

    <model-input
      label="Cost of FGF-2 ($/gram)"
      units="$ per gram"
      definition="US dollar cost of fibroblast growth factor-2 per gram"
      :default="model_inputs.FGF2_cost"
      :min="0"
      :max="4e6"
      :interval="1e5"
      fmtFun="commaFmt"
      @valueUpdate="(v, s)=>updateValue('FGF2_cost', v, s)"></model-input>

    <model-input
      label="Glucose concentration in basal media (mol/L)"
      units="mol/L"
      definition="This is the moles of glucose per liter of basal media. This is the primary carbon source, “food” for the cell."
      :default="model_inputs.GConInBM"
      :min="8.9e-3"
      :max="7.12e-2"
      :interval="1e-4"
      :precision="4"
      fmtFun="sciFmt"
      @valueUpdate="(v, s)=>updateValue('GConInBM', v, s)"></model-input>

    <model-input
      label="Glucose consumption rate per cell (mol/(h*million cells))"
      units="mol/(h*million cells)"
      definition="This is the moles of glucose consumed per million cells in an hour. This is the basis for the media requirement and the limitations of this are discussed within the paper."
      :default="model_inputs.Ug"
      :min="1e-8"
      :max="1e-6"
      :interval="1e-8"
      :precision="10"
      fmtFun="sciFmt"
      @valueUpdate="(v, s)=>updateValue('Ug', v, s)"></model-input>

    <model-input
      label="Cell doubling time (hours)"
      units="hours"
      definition="This is the amount of time it takes for the number of cells to double."
      :default="model_inputs.d"
      :min="4"
      :max="48"
      :interval="1"
      fmtFun="commaFmt"
      @valueUpdate="(v, s)=>updateValue('d', v, s)"></model-input>
    
    <model-input
      label="Cell maturation time (hours)"
      units="hours"
      definition="This is the amount of time it takes for cells to mature into mature muscle cells. This is the time cells spend in the bioreactor once the achievable cell concentration is reached."
      :default="model_inputs.MatTime"
      :min="4"
      :max="480"
      :interval="1"
      fmtFun="commaFmt"
      @valueUpdate="(v, s)=>updateValue('MatTime', v, s)"></model-input>

    <hr class="my-hr">
    <h5 class="mediaheader">Additional Media Costs ($/g)</h5>
    <cost-buttons label="Base Media ($/L)"
      definition="Cost of base media containing over 50 ingredients"
      :low="0.24" :high="3.12" :default="model_inputs.BaseMediaCost"
      @costChanged="(v, s)=>updateValue('BaseMediaCost', v, s)"/>
    <cost-buttons label="TGF-b"
      definition="Transforming Growth Factor-b cost. Additional ingredient to basal media"
      :low="0" :high="8.09e7" :default="model_inputs.TGFBCost"
      @costChanged="(v, s)=>updateValue('TGFBCost', v, s)"/>
    <cost-buttons label="Transferrin"
      definition="Additional ingredient to basal media"
      :low="0" :high="400" :default="model_inputs.TransferrinCost"
      @costChanged="(v, s)=>updateValue('TransferrinCost', v, s)"/>
    <cost-buttons label="AA2p"
      definition="L-Ascorbic acid 2-phosphate costs. Additional ingredient to basal media"
      :low="0" :high="7.84" :default="model_inputs.AA2pCost"
      @costChanged="(v, s)=>updateValue('AA2pCost', v, s)"/>
    <cost-buttons label="NaHCO3"
      definition="Sodium bicarbonate costs. Additional ingredient to basal media"
      :low="0" :high="0.01" :default="model_inputs.NaHCO3Cost"
      @costChanged="(v, s)=>updateValue('NaHCO3Cost', v, s)"/>
    <cost-buttons label="Na2SeO3"
      definition="Sodium selenite costs. Additional ingredient to basal media"
      :low="0" :high="0.1" :default="model_inputs.NaSeCost"
      @costChanged="(v, s)=>updateValue('NaSeCost', v, s)"/>
    <cost-buttons label="Insulin"
      definition="Additional ingredient to basal media"
      :low="0" :high="340" :default="model_inputs.InsulinCost"
      @costChanged="(v, s)=>updateValue('InsulinCost', v, s)"/>
  </div>
</template>

<script>
import axios from "axios";
import ScenarioButtons from "./ScenarioButtons.vue"
import ModelInput from "./ModelInput.vue";
import CostButtons from "./CostButtons.vue";

const api_endpoint = process.env.VUE_APP_API_ENDPOINT

export default {
  name: 'Model',
  data: function() {
    return {
      selectedScenario: null,
      model_inputs: null,
      model_outputs: {
        // outputs (to be plotted)
        name: 'scenario custom',
        Costs_Bioequip: null,
        Min_Cap_Exp: null,
        Costs_Fixed_Manu: null,
        Media_Costs: null,
        O2_costs: null,
        Elect_costs: null,
        Labor_costs: null,
        Non_Electric_costs: null,
        Min_Ann_Op_Cost: null,
        cap_expend_with_debt_equity: null,
        Min_ACBM_tomeet_Exp: null,
        Min_Ann_Cap_Op_Expend: null,
        Min_ACBM_Price: null,
        // more outputs
        BioReact: null,
        AnnBatches: null,
        Media_Vol: null,
        MediaChargeBatch: null,
        TotGluConBatch: null,
        GluInCharge: null,
        GluConInMatPhase: null,
        GluConInGrowthPhase: null,
        AnnVolMedia: null,
        Media_Cost: null,
        Ann_O2_Consum: null,
        O2_consum_batch: null,
        total_O2_cons_growth: null,
        total_Elect: null,
        Elect_Cool_BioReact: null,
        Elect_Heat_Media: null,
        tot_ann_payment: null
      }
    }
  },
  created() {
    this.model_inputs = {
      // user input values
      ACC: 10,              // achievable cell concentration (milion cells/mL)
      BRWV: 20000,          // bioreactor working volume (L)
      FGF2Con: 0.0001,      // concentration of FGF-2 (g/L)
      GConInBM: 0.0178,     // glucose concentration in basal media (mol/L)
      Ug: 0.000000413,      // glucose consumption rate per cell (mol/(h*million cells))
      d: 24,                // cell doubling time (h)
      MatTime: 240,         // cell maturation time (h)

      // other input values
      BaseMediaCost: 3.12,
      FGF2_cost: 2005000,   // cost of FGF-2 ($/g)
      TGFBCost: 80900000,
      TransferrinCost: 400,
      AA2pCost: 7.84,
      NaHCO3Cost: 0.01,
      NaSeCost: 0.1,
      InsulinCost: 340
    }
  },
  mounted() {
    this.$refs.scBtn.setScenario(1)
  },
  components: {
    ScenarioButtons,
    ModelInput,
    CostButtons
  },
  watch: {
    model_inputs: {
      handler: function() {

        // change inputs to cells (not million cells), which is what the model expects
        let model_inputs_adj = JSON.parse(JSON.stringify(this.model_inputs));
        model_inputs_adj['ACC'] = this.model_inputs['ACC'] * 1e6
        model_inputs_adj['Ug'] = this.model_inputs['Ug'] / 1e6
        
        axios
          .post(`${api_endpoint}model/`, JSON.stringify(model_inputs_adj))
          .then(response => {
            const s = response.data
            
            this.model_outputs.Costs_Bioequip = s.Costs_Bioequip;
            this.model_outputs.Min_Cap_Exp = s.Min_Cap_Exp;
            this.model_outputs.Costs_Fixed_Manu = s.Costs_Fixed_Manu;
            this.model_outputs.Media_Costs = s.Media_Costs;
            this.model_outputs.O2_costs = s.O2_costs;
            this.model_outputs.Elect_costs = s.Elect_costs;
            this.model_outputs.Labor_costs = s.Labor_costs;
            this.model_outputs.Non_Electric_costs = s.Non_Electric_costs;
            this.model_outputs.Min_Ann_Op_Cost = s.Min_Ann_Op_Cost;
            this.model_outputs.cap_expend_with_debt_equity = s.cap_expend_with_debt_equity;
            this.model_outputs.Min_ACBM_tomeet_Exp = s.Min_ACBM_tomeet_Exp;
            this.model_outputs.Min_Ann_Cap_Op_Expend = s.Min_Ann_Cap_Op_Expend;
            this.model_outputs.Min_ACBM_Price = s.Min_ACBM_Price;

            this.model_outputs.BioReact = s.BioReact;
            this.model_outputs.AnnBatches = s.AnnBatches;
            this.model_outputs.Media_Vol = s.Media_Vol;
            this.model_outputs.MediaChargeBatch = s.MediaChargeBatch;
            this.model_outputs.TotGluConBatch = s.TotGluConBatch;
            this.model_outputs.GluInCharge = s.GluInCharge;
            this.model_outputs.GluConInMatPhase = s.GluConInMatPhase;
            this.model_outputs.GluConInGrowthPhase = s.GluConInGrowthPhase;
            this.model_outputs.AnnVolMedia = s.AnnVolMedia;
            this.model_outputs.Media_Cost = s.Media_Cost;
            this.model_outputs.Ann_O2_Consum = s.Ann_O2_Consum;
            this.model_outputs.O2_consum_batch = s.O2_consum_batch;
            this.model_outputs.total_O2_cons_growth = s.total_O2_cons_growth;
            this.model_outputs.total_Elect = s.total_Elect;
            this.model_outputs.Elect_Cool_BioReact = s.Elect_Cool_BioReact;
            this.model_outputs.Elect_Heat_Media = s.Elect_Heat_Media;
            this.model_outputs.tot_ann_payment = s.tot_ann_payment;
            
            this.$emit('modelUpdate', this.selectedScenario, this.model_outputs);
          });
        },
      deep: true        // watch for change in any input value
    },
    selectedScenario: function(scen) {
      if (scen) {
        // set inputs to a featured scenario
        this.model_inputs.ACC = scen.acc;
        this.model_inputs.BRWV = scen.brv;
        this.model_inputs.FGF2Con = scen.fgf2_conc;
        this.model_inputs.GConInBM = scen.gcbm;
        this.model_inputs.Ug = scen.gcrc;
        this.model_inputs.d = scen.hpd;
        this.model_inputs.MatTime = scen.mt;
  
        this.model_inputs.FGF2_cost = scen.fgf2_cost;
  
        this.model_inputs.BaseMediaCost = scen.basemedia;
        this.model_inputs.TGFBCost = scen.tgfb;
        this.model_inputs.TransferrinCost = scen.transferrin;
        this.model_inputs.AA2pCost = scen.aa2p;
        this.model_inputs.NaHCO3Cost = scen.nahco3;
        this.model_inputs.NaSeCost = scen.nase;
        this.model_inputs.InsulinCost = scen.insulin;
      }
    }
  },
  methods: {
    updateValue: function(input, val, scenarioUpdate) {
      this.model_inputs[input] = val;
      // if not a scenario update, then the user is making custom modifications
      if (!scenarioUpdate) {
        this.selectedScenario = null;
      }
    }
  }
}
</script>

<style scoped>
.model-inputs {
  background-color: inherit;
}
.my-hr {
  border-top: 1px solid #b1b3b3;
  margin: 15px 0 5px 0;
}
.mediaheader {
  margin: 0 0 5px 0;
}
</style>
