<template>
  <div class="model-input">
    <!-- label -->
    <label for="model-slider">
      <span class='variable'>{{ label }}</span>
      <span class="help">
        <img src="../../assets/images/icons/info-24px.svg" class="info" alt="info" width="15" height="15" />
        <span class="tooltiptext">{{ definition }}</span>
      </span>
    </label>
    
    <!-- slider -->
    <div class="o-flex-region">
      <div class="slider-wrapper">
        <vue-slider
          id="model-slider"
          class="slider"
          v-model.number="value"
          :min="min"
          :max="max"
          :interval="interval"
          tooltip="hover"
          :lazy="true"
          :tooltip-formatter="v => fmtTooltip(v)"
          @error="error(type=$event, min_val=min, max_val=max)"
          @change="clearErrorMsg; scenario = false;"
        ></vue-slider>
      </div>
      <!-- numeric input -->
      <div class="custom-input-wrapper">
        <currency-input
          class="custom-input"
          locale="en-US"
          :currency="null"
          :precision="precision"
          :valueAsInteger="!precision"
          :value="value"
          @change="value = $event; scenario = false;"
          @input="clearErrorMsg" 
        />
      </div>
    </div>
    <span style="color: red;">{{ errorMsg }}</span>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { CurrencyInput } from 'vue-currency-input'
import { formatScientific } from "../../util.js"

// import { ERROR_TYPE } from 'vue-slider-component'
const ERROR_TYPE = {
  VALUE: 1,
  INTERVAL: 2,
  MIN: 3,
  MAX: 4,
  ORDER: 5,
}

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    units: {
      type: String,
      required: true
    },
    definition: {
      type: String,
      required: true
    },
    default: {
      type: Number,
      required: true
    },
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    interval: {
      type: Number,
      required: true
    },
    precision: {
      type: Number,
      default: 0
    },
    fmtFun: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      value: this.default,
      scenario: true,
      commaFmt: v => `${('' + v).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      sciFmt: v => v.toExponential(),
      errorMsg: ''
    }
  },
  components: {
    VueSlider,
    CurrencyInput
  },
  watch: {
    default: function() {
      // (handles when the default is updated by a scenario button)
      this.value = this.default;
      this.scenario = true;
      this.clearErrorMsg();
    },
    value: function() {
      this.$emit("valueUpdate", this.value, this.scenario)
    }
  },
  methods: {
    fmtTooltip(val) {
      let tt = ''
      switch (this.fmtFun) {
        case "commaFmt":
          tt = this.commaFmt(val);
          break;
        case "sciFmt":
          tt = this.sciFmt(val);
          break
      }
      if (this.units.startsWith('$ ')) {
        tt = `$${tt} ${this.units.replace(/^(\$ )/, "")}`
      } else {
        tt = `${tt} ${this.units}`
      }
      return tt
    },
    error(type, min_val, max_val) {
        switch (type) {
          case ERROR_TYPE.MIN:
            this.errorMsg = `Must be greater than ${this.$options.filters.commaFormat(min_val)}`
            break
          case ERROR_TYPE.MAX:
            this.errorMsg = `Must be less than ${this.$options.filters.commaFormat(max_val)}`
            break
          case ERROR_TYPE.VALUE:
            this.errorMsg = "Value error"
            break
        }
    },
    clearErrorMsg() {
      this.errorMsg = ''
    },
    SciFormat: function(x) {
      return formatScientific(x)
    }
  },
  filters: {
    commaFormat: function(x) {         // TODO: mixin
      return x !== null ? x.toLocaleString(undefined, { maximumFractionDigits: 0 }) : '';
    }
  }
}
</script>

<style scoped>
  .model-input {
    line-height: 18px;
    font-size: 15px;
  }
  .model-input > label {
    padding: 5px 0 1px 0;
    line-height: 20px;
  }

  .help {
    margin-left: 3px;
  }
  .info {
    vertical-align: top;
  }
  .help .tooltiptext {
    visibility: hidden;
    background-color: #335379;
    color: #fff;
    font-weight: normal;
    border-radius: 6px;
    max-width: 250px;
    margin-left: 5px;
    padding: 3px 10px;
    position: absolute;
    z-index: 10;
  }
  .help:hover .tooltiptext {
    visibility: visible;
  }

  .model-input .variable {
    font-style: italic;
  }
  .slider-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
  }
  .slider {
    flex: 0 1 90%;
  }
  .custom-input-wrapper {
    flex: 0 0 min(50%, 12ch);
  }
  .custom-input {
    height: 1.4em;
    padding: 0 5px;
    text-align: right;
  }
</style>
