<template>
    <header class="l-header header" role="banner">
        <div class="header__bar">
            <a href="#primary-nav" class="nav-toggle js-nav-toggle" aria-controls="primary-nav" aria-expanded="false" aria-label="Toggle Main Menu">
                <span class="nav-toggle__icon nav-toggle__icon--menu">Menu</span>
            </a>
            <div class="l-container"><a href="https://www.ucdavis.edu/" class=""><img src="../assets/images/uc-logo-white.svg" class="ucd-logo" alt="UC Davis Logo" /></a>
            </div>
        </div>
        <div class="header__branding">
            <div class="l-container o-media">
                <div class="o-media__figure nav-item"><a @click="pageSelected='about'"><img src="../assets/images/iifh.svg" class="site-logo" alt="Site Logo" /></a>
                </div>
                <div class="o-media__body">
                    <h1 class="header__site-name">Animal Cell-Based Meat Cost Calculator</h1>
                    <div class="header__slogan">Innovation Institute for Food and Health</div>
                </div>
            </div>
        </div>
        <div class="l-navbar navbar menu--closed">
            <div class="l-container--navigation off-canvas off-canvas--left">
                <div class="off-canvas__container">
                <nav id="primary-nav" class="primary-nav primary-nav--superfish" aria-label="Main Menu">
                    <ul class="menu sf-js-enabled sf-arrows">
                        <li class='menu-item nav-item'><a @click="pageSelected='about'">About</a></li>
                        <li class='menu-item nav-item'><a @click="pageSelected='calculator'">Cost Calculator</a></li>
                    </ul>
                </nav>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { EventBus } from '../main.js'

export default {
    data: function() {
        return {
            pageSelected: ''
        }
    },
    watch: {
        pageSelected: function() {
            EventBus.$emit('pageChanged', this.pageSelected)
        }
    }
}
</script>

<style scoped>
    .nav-item:hover {
        cursor: pointer;
    }
    .l-navbar {
        padding: 0;
    }
    .site-logo {
        height: 100px;
    }
</style>
