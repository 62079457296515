<template>
  <div id='barplot'>
    <h4 id='title' ref="t"></h4>
    <div id='plotContainer'>
      <div id='plot' ref="p"></div>
      <div id='legend'>
        <svg font-size="14">
          <text x="0" y="15" font-size="16" text-decoration="underline">Cost Categories</text>
          <g transform="translate(10,25)">
            
            <rect x="0" y="10" width="10" height="10" fill="rgb(217, 95, 2)"></rect>
            <text x="20" y="15">Media</text>
            
            <rect x="0" y="30" width="10" height="10" fill="rgb(117, 112, 179)"></rect>
            <text x="20" y="35">Bioreactor</text>

            <rect x="0" y="50" width="10" height="10" fill="rgb(231, 41, 138)"></rect>
            <text x="20" y="55">Labor</text>

            <rect x="0" y="70" width="10" height="10" fill="rgb(102, 166, 30)"></rect>
            <text x="20" y="75">Energy</text>

            <rect x="0" y="90" width="10" height="10" fill="rgb(230, 171, 2)"></rect>
            <text x="20" y="95">Manufacturing</text>

            <rect x="0" y="110" width="10" height="10" fill="rgb(166, 118, 29)"></rect>
            <text x="20" y="115">Non-Electric Utility</text>

            <rect x="0" y="130" width="10" height="10" fill="rgb(102, 102, 102)"></rect>
            <text x="20" y="135">Oxygen</text>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
  import * as d3 from "d3";
  import * as util from "../../util.js";

  const variables = [
    {variable: 'Media_Costs', label: 'Media'},
    {variable: 'Costs_Bioequip', label: 'Bioreactor'},
    {variable: 'Labor_costs', label: 'Labor'},
    {variable: 'Elect_costs', label: 'Energy'},
    {variable: 'Costs_Fixed_Manu', label: 'Manufacturing'},
    {variable: 'Non_Electric_costs', label: 'Non-Electric Utility'},
    {variable: 'O2_costs', label: 'Oxygen'}
  ];
  const color = d3.scaleOrdinal(d3["schemeDark2"]).domain(variables);

  export default {
    props: {
      plotdata: {
        type: Array,
        required: true,
        validator: (prop) => prop.every(e => {
          return typeof e === 'object'
        })
      }
    },
    data: function() {
      return {
        width: 400,
        height: 260,
        margin: {top: 20, right: 10, bottom: 50, left: 70}
      }
    },
    computed: {
      graphWidth: function() { return this.width - this.margin.left - this.margin.right },
      graphHeight: function() { return this.height - this.margin.top - this.margin.bottom }
    },
    mounted() {
      // add resize handler
      window.addEventListener('resize', this.handleResize)

      // set up plot
      var plot = d3.select(this.$refs.p);

      // main SVG
      var svg = plot.append('svg')
        .attr("viewBox", [0, 0, this.width, this.height]);
      
      // title
      d3.select(this.$refs.t)
        .html(
          "Cost to replace 1% of the beef produced in the United States in 2018 with ACBM" +
          "<span class='plotunit'>(121,000,000 kg/year)</span>"
        );
      
      // graph group
      var graph = svg.append('g')
        .attr('width', this.graphWidth)
        .attr('height', this.graphHeight)
        .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
      
      const data = this.plotdata
      
      // x scale & axis
      var scenarioAxisGroup = graph.append('g').attr("class", "xaxis")
        .attr('transform', `translate(0, ${this.graphHeight})`);
      const scenarios = data.map(d => d.name);
      const scenarioScale = d3.scaleBand()
        .domain(scenarios)
        .range([0, this.graphWidth])
        .padding(0.1);
      const scenarioAxis = d3.axisBottom(scenarioScale)
        .ticks()
        .tickFormat((d) => util.scenarioLabels(d));
      scenarioAxisGroup.call(scenarioAxis);

      scenarioAxisGroup.append("text").attr("class", "xaxislabel")
        .attr("y", this.margin.bottom / 2)
        .attr("x", this.graphWidth / 2)
        .attr("dy", "1.2em")
        .style("text-anchor", "middle")
        .style("fill", "black")
        .text("Scenarios");

      const variableScale = d3.scaleBand()
        .domain(variables.map(d => d.variable))
        .range([0, scenarioScale.bandwidth()])
        .padding(0.1);
      
      // y scale & axis
      var yAxisGroup = graph.append('g').attr("class", "yaxis");
      let max_y = d3.max(data.map(d => d3.max(variables.map(x => d[x.variable]))))
      const yScale = (d3.scaleLog()
        .domain([1, max_y])
        .range([this.graphHeight, 0]))
        .nice();
      const yAxis = d3.axisLeft(yScale).ticks(10, util.formatPower);
      yAxisGroup.call(yAxis);

      yAxisGroup.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 - this.margin.left)
        .attr("x", 0 - (this.graphHeight / 2))
        .attr("dy", "1.2em")
        .style("text-anchor", "middle")
        .style("fill", "black")
        .text("US Dollars ($)");

      // tooltip
      var tooltip = d3.select("body").append("div")
        .attr("class", "tooltip")
        .style('opacity', 0);
      
      // initial data
      graph.selectAll("scenarios")
            .data(data, d => d.name).enter().append('g')
            .attr("class", "scenarios")
            .attr("transform", d => `translate(${scenarioScale(d.name)}, 0)`)
          .selectAll("data_rect")
            .data(d => variables.map(function(x) {return {variable: x.variable, value: d[x.variable], label: x.label, scenario: d.name}}))
            .enter().append("rect")
            .attr("class", "data_rect")
            .attr("x", d => variableScale(d.variable))
            .attr("y", d => yScale(d.value))
            .attr("width", () => variableScale.bandwidth())
            .attr("height", d => this.graphHeight - yScale(d.value))
            .attr("fill", d => color(d.variable))
            .attr("opacity", d => d.scenario == "scenario custom" ? 1 : 0.6)
            // tooltip
            .on('mouseover', (d, i, n) => {
              d3.select(n[i])
                .transition()
                .duration(100)  // ms
                .style('opacity', 0.8);
              tooltip.transition(200).style('opacity', 0.9);
              tooltip.html(d.label + '<br><strong>' + this.$options.filters.priceFormat(d.value) + '</strong>')
            })
            .on('mousemove', () => {
              tooltip.style('left', (d3.event.pageX - 10) + 'px')
                .style('top', (d3.event.pageY - 25) + 'px');
            })
            .on('mouseout', (d, i, n) => {
              d3.select(n[i])
                .transition()
                .duration(100)  // ms
                .style('opacity', d => d.scenario == "scenario custom" ? 1 : 0.6)
            
            tooltip.transition(500).style('opacity', 0);
          });
      
      // do initial resize
      this.handleResize()

      // save references for later use
      this.$options.graph = graph;
      this.$options.scenarioScale = scenarioScale;
      this.$options.variableScale = variableScale;
      this.$options.yScale = yScale;
      this.$options.scenarioAxis = scenarioAxis;
    },
    watch: {
      // update plot
      plotdata: function(data) {
        const graph = this.$options.graph;
        const yScale = this.$options.yScale;

        // bars
        graph.selectAll(".scenarios")
            .data(data, d => d.name)
          .selectAll(".data_rect")
            .data(d => variables.map(function(x) {return {variable: x.variable, value: d[x.variable], label: x.label, scenario: d.name}}))
          .transition(500)
          .attr("y", d => yScale(d.value))
          .attr("height", d => this.graphHeight - yScale(d.value))
      },
      width: function() {
        var scenarioScale = this.$options.scenarioScale;
        var scenarioAxis = this.$options.scenarioAxis;
        const variableScale = this.$options.variableScale;
        
        // redraw the barplot
        let elem = d3.select(this.$refs.p)

        // resize main SVG and graph group
        let svg = elem.select('svg')
        svg.attr("viewBox", [0, 0, this.width, this.height])
        const graph = this.$options.graph;
        graph.attr('width', this.graphWidth)

        // resize x scale & axis
        scenarioScale.range([0, this.graphWidth]);
        elem.select('.xaxis').call(scenarioAxis);
        graph.selectAll('.xaxislabel')
          .attr("x", this.graphWidth / 2)
        
        // resize bars
        variableScale.range([0, scenarioScale.bandwidth()]);
        graph.selectAll(".scenarios")
            .attr("transform", d => `translate(${scenarioScale(d.name)}, 0)`)
          .selectAll(".data_rect")
            .attr("x", d => variableScale(d.variable))
            .attr("width", () => variableScale.bandwidth())
      }
    },
    methods: {
      handleResize: function() {
        let elem = this.$refs.p
        if (elem) {
          let rect = elem.getBoundingClientRect()
          this.width = rect.width
        }
      }
    },
    filters: {
      priceFormat: function(x) {         // TODO: mixin
        return x !== null ? `$${x.toLocaleString(undefined, { maximumFractionDigits: 0 })}` : '';
      }
    }
  }
</script>

<style scoped>
  #plotContainer {
    display: flex;
  }
  #plot {
    flex-grow: 1;
    flex-basis: 70%;
    display: inline-block;
  }
  #legend {
    flex-basis: 30%;
    min-width: 150px;
    max-width: 150px;
    margin-bottom: 0;
    height: 260px;
  }
  #legend text {
    alignment-baseline: middle;
  }
  #legend > svg {
    width: 100%;
    height: 100%;
  }
</style>
