<template>
  <div>
    <a @click.prevent="downloadCSVData()">Download Custom Scenario Data (csv)</a>
  </div>
</template>

<script>
export default {
  name: 'DownloadData',
  props: {
    custom_scenario: {
      type: Object,
      required: true
    }
  },
  methods: {
    downloadCSVData: function() {
      // header
      let csv = Object.keys(this.custom_scenario).join(',');
      csv += "\n";

      // values
      csv += Object.values(this.custom_scenario).join(',');
      csv += "\n";
      
      // download
      let anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'acbm_custom_scenario.csv';
      anchor.click();
    }
  }
}
</script>

<style scoped>

</style>
