export function commaFormat (x) {
    return x !== null ? x.toLocaleString(undefined, { maximumFractionDigits: 0 }) : '';
}

export function formatPower(x) {
    const e = Math.log10(x);
    if (e !== Math.floor(e)) return; // Ignore non-exact power of ten.
    return `10${(e + "").replace(/./g, c => "⁰¹²³⁴⁵⁶⁷⁸⁹"[c] || "⁻")}`;
}

export function scenarioLabels(scen) {
    let label = "";
    switch(scen) {
        case "scenario 1":
        label = "1"; break;
        case "scenario 2":
        label = "2"; break;
        case "scenario 3":
        label = "3"; break;
        case "scenario 4":
        label = "4"; break;
        case "scenario custom":
        label = "Custom"; break;
        default:
        label = "";
    }
    return label;
}

export function formatScientific(num, precision = 2) {
    const numInSciNot = {};
    [numInSciNot.coefficient, numInSciNot.exponent] = 
        num.toExponential().split('e').map(item => Number(item));
    numInSciNot.coefficient = numInSciNot.coefficient.toFixed(precision)
    return `${numInSciNot.coefficient} x 10<sup>${numInSciNot.exponent}</sup>`
}
