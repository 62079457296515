<template>
  <div id="model-outputs">
    <table class="table--bordered">
      <tr class="groupheader">
        <th class="topleftcell"></th>
        <th class="priceheader" rowspan="2">Min. price of ACBM ($/kg)</th>
        <th colspan="10">Annual Costs ($M)</th>
      </tr>
      <tr class="varheader">
        <th>Scenario</th>
        <th scope="col">Media</th>
        <th scope="col">Bioreactor</th>
        <th scope="col">Labor</th>
        <th scope="col">Energy</th>
        <th scope="col">Fixed<br>Manufacturing</th>
        <th scope="col">Non-Electric<br>Utility</th>
        <th scope="col">Oxygen</th>
        <th scope="col">Min. Op.<br>Costs</th>
        <th scope="col">Min. Capital<br>&amp; Op.</th>
        <th scope="col">Exp. with<br>Debt and Eq.</th>
      </tr>
      <tr class="fixedScen">
        <td class="rowHeader" scope="row">1</td>
        <td class="priceCol">{{ model_outputs[0]['Min_ACBM_Price'] | priceFormat }}</td>
        <td>{{ model_outputs[0]['Media_Costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[0]['Costs_Bioequip'] | priceMilFormat }}</td>
        <td>{{ model_outputs[0]['Labor_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[0]['Elect_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[0]['Costs_Fixed_Manu'] | priceMilFormat }}</td>
        <td>{{ model_outputs[0]['Non_Electric_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[0]['O2_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[0]['Min_Ann_Op_Cost'] | priceMilFormat }}</td>
        <td>{{ model_outputs[0]['Min_Ann_Cap_Op_Expend'] | priceMilFormat }}</td>
        <td>{{ model_outputs[0]['cap_expend_with_debt_equity'] | priceMilFormat }}</td>
      </tr>
      <tr class="fixedScen">
        <td class="rowHeader" scope="row">2</td>
        <td class="priceCol">{{ model_outputs[1]['Min_ACBM_Price'] | priceFormat }}</td>
        <td>{{ model_outputs[1]['Media_Costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[1]['Costs_Bioequip'] | priceMilFormat }}</td>
        <td>{{ model_outputs[1]['Labor_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[1]['Elect_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[1]['Costs_Fixed_Manu'] | priceMilFormat }}</td>
        <td>{{ model_outputs[1]['Non_Electric_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[1]['O2_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[1]['Min_Ann_Op_Cost'] | priceMilFormat }}</td>
        <td>{{ model_outputs[1]['Min_Ann_Cap_Op_Expend'] | priceMilFormat }}</td>
        <td>{{ model_outputs[1]['cap_expend_with_debt_equity'] | priceMilFormat }}</td>
      </tr>
      <tr class="fixedScen">
        <td class="rowHeader" scope="row">3</td>
        <td class="priceCol">{{ model_outputs[2]['Min_ACBM_Price'] | priceFormat }}</td>
        <td>{{ model_outputs[2]['Media_Costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[2]['Costs_Bioequip'] | priceMilFormat }}</td>
        <td>{{ model_outputs[2]['Labor_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[2]['Elect_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[2]['Costs_Fixed_Manu'] | priceMilFormat }}</td>
        <td>{{ model_outputs[2]['Non_Electric_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[2]['O2_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[2]['Min_Ann_Op_Cost'] | priceMilFormat }}</td>
        <td>{{ model_outputs[2]['Min_Ann_Cap_Op_Expend'] | priceMilFormat }}</td>
        <td>{{ model_outputs[2]['cap_expend_with_debt_equity'] | priceMilFormat }}</td>
      </tr>
      <tr class="fixedScen">
        <td class="rowHeader" scope="row">4</td>
        <td class="priceCol">{{ model_outputs[3]['Min_ACBM_Price'] | priceFormat }}</td>
        <td>{{ model_outputs[3]['Media_Costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[3]['Costs_Bioequip'] | priceMilFormat }}</td>
        <td>{{ model_outputs[3]['Labor_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[3]['Elect_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[3]['Costs_Fixed_Manu'] | priceMilFormat }}</td>
        <td>{{ model_outputs[3]['Non_Electric_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[3]['O2_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[3]['Min_Ann_Op_Cost'] | priceMilFormat }}</td>
        <td>{{ model_outputs[3]['Min_Ann_Cap_Op_Expend'] | priceMilFormat }}</td>
        <td>{{ model_outputs[3]['cap_expend_with_debt_equity'] | priceMilFormat }}</td>
      </tr>
      <tr class="customScen">
        <td class="rowHeader" scope="row">Custom</td>
        <td class="priceCol">{{ model_outputs[4]['Min_ACBM_Price'] | priceFormat }}</td>
        <td>{{ model_outputs[4]['Media_Costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[4]['Costs_Bioequip'] | priceMilFormat }}</td>
        <td>{{ model_outputs[4]['Labor_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[4]['Elect_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[4]['Costs_Fixed_Manu'] | priceMilFormat }}</td>
        <td>{{ model_outputs[4]['Non_Electric_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[4]['O2_costs'] | priceMilFormat }}</td>
        <td>{{ model_outputs[4]['Min_Ann_Op_Cost'] | priceMilFormat }}</td>
        <td>{{ model_outputs[4]['Min_Ann_Cap_Op_Expend'] | priceMilFormat }}</td>
        <td>{{ model_outputs[4]['cap_expend_with_debt_equity'] | priceMilFormat }}</td>
      </tr>
    </table>

    <!-- <div>
      Mass of meat being produced is {{ desired_mass_meat | commaFormat }} kg<br>
      Number of bioreactors is {{ model_outputs['BioReact'] | commaFormat }}<br>
      Number of annual batches is {{ model_outputs['AnnBatches'] | commaFormat }}<br>
      Bioreactor cost is ${{ model_outputs['Costs_Bioequip'] | commaFormat }}<br>
      Fixed manufacturing cost is ${{ model_outputs['Costs_Fixed_Manu'] | commaFormat }}<br>
      Volume of media per batch is {{ model_outputs['Media_Vol'] | commaFormat }} L<br>
      Media charge per batch is {{ model_outputs['MediaChargeBatch'] | commaFormat }}<br>
      Total glucose consumed per batch is {{ model_outputs['TotGluConBatch'] | commaFormat }}<br>
      Glucose per charge is {{ model_outputs['GluInCharge'] | commaFormat }}<br>
      Glucose consumed during maturation is {{ model_outputs['GluConInMatPhase'] | commaFormat }}<br>
      Glucose consumed during growth is {{ model_outputs['GluConInGrowthPhase'] | commaFormat }}<br>
      Annual volume of media is {{ model_outputs['AnnVolMedia'] | commaFormat }} liters<br>
      Cost of media is ${{ model_outputs['Media_Cost'] | commaFormat }} per liter<br>
      Annual cost of media is ${{ model_outputs['Media_Costs'] | commaFormat }}<br>
      Annual consumption of O2 is {{ model_outputs['Ann_O2_Consum'] | commaFormat }}<br>
      Consumption of O2 per batch is {{ model_outputs['O2_consum_batch'] | commaFormat }}<br>
      Annual cost of O2 is ${{ model_outputs['O2_costs'] | commaFormat }}<br>
      O2 consumed in growth phase is {{ model_outputs['total_O2_cons_growth'] | commaFormat }}<br>
      Annual energy cost is ${{ model_outputs['Elect_costs'] | commaFormat }}<br>
      Total energy requirement is {{ model_outputs['total_Elect'] | commaFormat }}<br>
      Energy requirement to cool bioreactors is {{ model_outputs['Elect_Cool_BioReact'] | commaFormat }} kWh<br>
      Energy requirement to heat bioreactors is {{ model_outputs['Elect_Heat_Media'] | commaFormat }} kWh<br>
      Annual labor cost is ${{ model_outputs['Labor_costs'] | commaFormat }}<br>
      Annual non-electric utility cost is ${{ model_outputs['Non_Electric_costs'] | commaFormat }}<br>
      Total annual payment with captial expenditures is ${{ model_outputs['tot_ann_payment'] | commaFormat }}
    </div>
    <hr>
    <div>
      Minimum capital expenditures is ${{ model_outputs['Min_Cap_Exp'] | commaFormat }}<br>
      Capital expenditures, with debt and equity recovery, is ${{ model_outputs['cap_expend_with_debt_equity'] | commaFormat }}<br>
      Minimum annual operating costs are ${{ model_outputs['Min_Ann_Op_Cost'] | commaFormat }}<br>
      Minimum price of ACBM to meet annual operating expenses are ${{ model_outputs['Min_ACBM_tomeet_Exp'] | commaFormat }} per kg<br>
      Minimum annual capital and operating expenses are ${{ model_outputs['Min_Ann_Cap_Op_Expend'] | commaFormat }}<br>
      Minimum price of ACBM to meet annual capital and operating expenses are ${{ model_outputs['Min_ACBM_Price'] | commaFormat }} per kg
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'OutputTable',
  props: {
      model_outputs: {
        type: Array,
        required: true
      }
  },
  data: function() {
    return {
      desired_mass_meat: 121000000
    }
  },
  filters: {
    priceFormat: function(x) {         // TODO: mixin
      return x !== null ? `$${x.toLocaleString(undefined, { maximumFractionDigits: 0 })}` : '';
    },
    priceMilFormat: function(x) {         // TODO: mixin
      return x !== null ? `$${(x / 1e6).toLocaleString(undefined, { maximumFractionDigits: 0 })}M` : '';
    }
  }
}
</script>

<style scoped>
#model-outputs > div {
  font-size: 14px;
  line-height: 18px;
}
#model-outputs > table {
  font-size: 12px;
}
th {
  text-align: center;
}
td {
  padding: 0 3px;
  text-align: right;
}
.topleftcell {
  border-bottom: 0px;
}
.groupheader {
  line-height: 18px;
}
.varheader {
  line-height: 18px;
  white-space: nowrap
}
.priceheader {
  color: white;
  background-color: #4D688C;
}
.rowHeader {
  font-weight: bold;
  text-align: center;
}
.fixedScen {
  background-color: #FBF6E5;
}
.customScen {
  background-color: #F8EECC;
}
.priceCol {
  background-color: #E5E9EF;
}
.customScen .priceCol {
  background-color: #CCD4E0;
}
</style>
