<template>
  <div id="app" class="page l-header--fixed">
    <nav-header></nav-header>
    <keep-alive>
      <component :is='currentPage'></component>
    </keep-alive>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import * as ucd from './assets/js/scripts.js'   // UCD One Pattern Lab
/* eslint-enable no-unused-vars */
import { EventBus } from './main.js'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import About from './components/About.vue'
import Calculator from './components/calculator/Calculator.vue'

export default {
  name: 'App',
  data: function() {
    return {
      currentPage: 'about'
    }
  },
  components: {
    navHeader: Header,
    navFooter: Footer,
    about: About,
    calculator: Calculator
  },
  created() {
    EventBus.$on('pageChanged', page => this.currentPage = page)
  }
}
</script>

<style>
  @import './assets/css/style.css';   /* UCD One Pattern Lab */
</style>
