<template>
  <div class="o-flex-region cost-input">
    <div class="cost-label">
      {{ label }}
      <span class="help">
        <img src="../../assets/images/icons/info-24px.svg" class="info" alt="info" width="15" height="15" />
        <span class="tooltiptext">{{ definition }}</span>
      </span>
    </div>
    <ul class="tabs cost-buttons">
      <li class="cost-button"><a class="btn-link" @click="selection = 'low'; price = low; scenario = false;"
        :class="{ active: selection==='low' }">{{ low | priceFormat }}
      </a></li>
      <li class="cost-button"><a class="btn-link" @click="selection = 'high'; price = high; scenario = false;"
        :class="{ active: selection==='high' }">{{ high | priceFormat }}
      </a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CostButtons',
  props: {
    label: {
      type: String,
      required: true
    },
    definition: {
      type: String,
      required: true
    },
    low: {
      type: Number,
      required: true
    },
    high: {
      type: Number,
      required: true
    },
    default: {
      type: Number,
      required: true
    }
  },
  data: function() {
    return {
      selection: 'high',
      price: this.default,
      scenario: true
    }
  },
  watch: {
    default: function() {
      // (handles when the default is updated by a scenario button)
      this.selection = this.default == this.low ? "low" : "high"; 
      this.price = this.default;
      this.scenario = true;
    },
    price: function() {
      this.$emit("costChanged", this.price, this.scenario);
    }
  },
  filters: {
    priceFormat: function(x) {
      if (x == "80900000") return "$8.09×10⁷";    // special case for Tgf-b
      return `$${x.toFixed(2)}`;
    }
  }
}
</script>

<style scoped>
.cost-input {
  line-height: 18px;
  margin-bottom: 3px;
}
.cost-label {
  font-size: 16px;
  font-style: italic;
  flex: 1;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
}
.cost-label:after {
  content: " . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";
  color: #AAA;
}

.help {
  margin-left: 3px;
}
.info {
  vertical-align: top;
}
.help .tooltiptext {
  visibility: hidden;
  background-color: #335379;
  color: #fff;
  font-weight: normal;
  white-space: normal;
  border-radius: 6px;
  max-width: 250px;
  margin-left: 5px;
  padding: 3px 10px;
  position: absolute;
  z-index: 1;
}
.help:hover .tooltiptext {
  visibility: visible;
}

.cost-buttons {
  margin: 0;
  flex: 0 0 140px;       /* fixed width */
}
.cost-button {
  margin: 0;
}
.btn-link {
  width: 70px;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
}
</style>
