<template>
  <div id='container' ref="container">
    <h4 id='title' ref="t"></h4>
    <div id='plot' ref="p"></div>
  </div>
</template>

<script>
  import * as d3 from "d3";
  import * as util from "../../util.js";
  
  export default {
    props: {
      plotdata: {
        type: Array,
        required: true,
        validator: (prop) => prop.every(e => {
          return typeof e === 'object'
        })
      }
    },
    data: function() {
      return {
        width: 400,
        height: 260,
        margin: {top: 20, right: 50, bottom: 50, left: 60}
      }
    },
    computed: {
      graphWidth: function() { return this.width - this.margin.left - this.margin.right },
      graphHeight: function() { return this.height - this.margin.top - this.margin.bottom }
    },
    mounted() {
      // add resize handler
      window.addEventListener('resize', this.handleResize)

      // set up plot
      var plot = d3.select(this.$refs.p);
      
      // main SVG
      var svg = plot.append('svg')
        .attr("viewBox", [0, 0, this.width, this.height]);
      
      // title
      d3.select(this.$refs.t)
        .html("Minimum price for ACBM<span class='plotunit'>($/kg)</span>");
      
      // graph group
      var graph = svg.append('g')
        .attr('width', this.graphWidth)
        .attr('height', this.graphHeight)
        .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
      
      const data = this.plotdata
      
      // x scale & axis
      var scenarioAxisGroup = graph.append('g').attr("class", "xaxis")
        .attr('transform', `translate(0, ${this.graphHeight})`);
      const scenarios = data.map(d => d.name);
      const scenarioScale = d3.scaleBand()
        .domain(scenarios)
        .range([0, this.graphWidth])
        .padding(0.1);
      const scenarioAxis = d3.axisBottom(scenarioScale)
        .ticks()
        .tickFormat((d) => util.scenarioLabels(d));
      scenarioAxisGroup.call(scenarioAxis);

      scenarioAxisGroup.append("text").attr("class", "xaxislabel")
        .attr("y", this.margin.bottom / 2)
        .attr("x", this.graphWidth / 2)
        .attr("dy", "1.2em")
        .style("text-anchor", "middle")
        .style("fill", "black")
        .text("Scenarios");
      
      // y scale & axis
      var yAxisGroup = graph.append('g').attr("class", "yaxis");
      let max_y = d3.max(data, scen => scen["Min_ACBM_Price"])
      const yScale = (d3.scaleLog()
        .domain([1, max_y])
        .range([this.graphHeight, 0]))
        .nice();
      const yAxis = d3.axisLeft(yScale).ticks(10, util.formatPower);
      yAxisGroup.call(yAxis);

      yAxisGroup.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 - this.margin.left)
        .attr("x", 0 - (this.graphHeight / 2))
        .attr("dy", "1.2em")
        .style("text-anchor", "middle")
        .style("fill", "black")
        .text("US Dollars ($)");

      // tooltip div
      var tooltip = d3.select("body").append("div")
        .attr("class", "tooltip")
        .style('opacity', 0);
      
      // initial data
      graph.selectAll("scenarios")
            .data(data, d => d.name)
            .enter().append("rect")
            .attr("class", "scenarios")
            .attr("x", d => scenarioScale(d.name))
            .attr("y", d => yScale(d["Min_ACBM_Price"]))
            .attr("width", () => scenarioScale.bandwidth())
            .attr("height", d => this.graphHeight - yScale(d["Min_ACBM_Price"]))
            .attr("fill", d => d.name == "scenario custom" ? "#e6a65d" : "#999")
            // tooltip
            .on('mouseover', (d, i, n) => {
              d3.select(n[i])
                .transition()
                .duration(100)  // ms
                .style('opacity', 0.7);
              tooltip.transition(200).style('opacity', 0.9);
              tooltip.html('<strong>$' + util.commaFormat(d["Min_ACBM_Price"]) + '</strong>')
            })
            .on('mousemove', () => {
              tooltip.style('left', (d3.event.pageX - 10) + 'px')
                .style('top', (d3.event.pageY - 25) + 'px');
            })
            .on('mouseout', (d, i, n) => {
              d3.select(n[i])
                .transition()
                .duration(100)  // ms
                .style('opacity', 1)
            
            tooltip.transition(500).style('opacity', 0);
          });
        
        // labels
        graph.selectAll("labels")  
          .data(data, d => d.name)
          .enter().append("text")
          .attr("class", "label")
          .attr("x", d => scenarioScale(d.name) + scenarioScale.bandwidth() / 2)
          .attr("y", d => yScale(d["Min_ACBM_Price"]))
          .attr("dy", "-0.2em")
          .attr("font-size", "0.6em")
          .text(d  => this.$options.filters.priceFormat(d["Min_ACBM_Price"]));
        
        // add line at $10
        graph.append("line")
          .attr("class", "tendollarline")
          .style("stroke", "black")
          .attr("x1", scenarioScale('scenario 1'))
          .attr("y1", yScale(10))
          .attr("x2", scenarioScale('scenario custom') + (scenarioScale.bandwidth() * 1.2))
          .attr("y2", yScale(10));
        
        graph.append("text")
          .attr("class", "tendollarlabel")
          .attr("x", scenarioScale('scenario custom') + (scenarioScale.bandwidth() * 1.2) + 10)
          .attr("y", yScale(10))
          .text('$10')
      
      // do initial resize
      this.handleResize()
      
      // save references for later use
      this.$options.graph = graph;
      this.$options.scenarioScale = scenarioScale;
      this.$options.yScale = yScale;
      this.$options.scenarioAxis = scenarioAxis;
    },
    watch: {
      // update plot
      plotdata: function(data) {
        const graph = this.$options.graph;
        const yScale = this.$options.yScale;

        // bars
        graph.selectAll(".scenarios")
          .data(data, d => d.name)
          .transition(500)
          .attr("y", d => yScale(d["Min_ACBM_Price"]))
          .attr("height", d => this.graphHeight - yScale(d["Min_ACBM_Price"]))
        
        // labels
        graph.selectAll(".label")
          .data(data, d => d.name)
          .transition(500)
          .attr("y", d => yScale(d["Min_ACBM_Price"]))
          .text(d  => this.$options.filters.priceFormat(d["Min_ACBM_Price"]));
      },
      width: function() {
        // redraw the plot
        let elem = d3.select(this.$refs.container)

        // resize main SVG and graph group
        let svg = elem.select('svg')
        svg.attr("viewBox", [0, 0, this.width, this.height])
        const graph = this.$options.graph;
        graph.attr('width', this.graphWidth)

        // resize x scale & axis
        var scenarioScale = this.$options.scenarioScale;
        scenarioScale.range([0, this.graphWidth]);
        
        var scenarioAxis = this.$options.scenarioAxis;
        elem.select('.xaxis').call(scenarioAxis);

        graph.selectAll('.xaxislabel')
          .attr("x", this.graphWidth / 2)

        // resize bars, labels, and line
        graph.selectAll('.scenarios')
          .attr("x", d => scenarioScale(d.name))
          .attr("width", scenarioScale.bandwidth());

        graph.selectAll(".label")
          .attr("x", d => scenarioScale(d.name) + scenarioScale.bandwidth() / 2)
        
        graph.selectAll('.tendollarline')
          .attr("x1", scenarioScale('scenario 1'))
          .attr("x2", scenarioScale('scenario custom') + (scenarioScale.bandwidth() * 1.2))
        graph.selectAll('.tendollarlabel')
          .attr("x", scenarioScale('scenario custom') + (scenarioScale.bandwidth() * 1.2) + 10)
      }
    },
    methods: {
      handleResize: function() {
        let elem = this.$refs.p
        if (elem) {
          let rect = elem.getBoundingClientRect()
          this.width = rect.width
        }
      }
    },
    filters: {
      priceFormat: function(x) {         // TODO: mixin
        return x !== null ? `$${x.toLocaleString(undefined, { maximumFractionDigits: 0 })}` : '';
      }
    }
  }
</script>

<style>
  /* NOTE: these styles are not scoped --> they also apply to Barplot 2 */
  .label {
    text-anchor: middle;
  }
  .plotunit {
    font-weight: normal;
    margin-left: 10px;
  }
  .tooltip {
    position: absolute;
    transform-origin: center right;
    transform: translate(-100%, 0%);
    text-align: left;
    padding: 5px;
    font: 12px Helvetica, sans-serif;
    background: white;
    border: 0px;
    border-radius: 5px;
    pointer-events: none;
  }
  #title {
    margin-left: 10px;
    min-height: 60px;
    font-size: min(1.1875rem, 1.4vw);
  }
  .xaxis {
    font-size: 16px;
  }
  .yaxis {
    font-size: 16px;
  }
</style>
